export default {
    components: {},
    data() {
        return {
            android: false,
            ios: false,
        }
    },
    methods: {
        //点击下载按钮判断
        app_down() {
            window.open('http://download.wholeok.com/download');
        },
        app_down_android() {
            window.open('http://download.wholeok.com/download/android');
        },
        app_down_ios() {
            window.open('http://download.wholeok.com/download/ios');
        },
    },
    created() {
        // const __this = this;
    },
    mounted() {
        const __this = this;
        const ua = navigator.userAgent.toLowerCase();
        if (ua.indexOf('android') > -1) {
            this.android  = true;
        } else if(ua.indexOf('ios') > -1) {
            this.ios = true;
        }
        if (this.android) {
            this.app_down_android();
        } else if (this.ios) {
            this.app_down_ios();
        }
    },
    activated() {
        const __this = this;
    },
    deactivated() {
        // const __this = this;
    },

}
